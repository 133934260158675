import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import {Authority} from "./authority.constants";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private firstName?: string;
  private lastName?: string;

  constructor(private readonly keycloak: KeycloakService) {
    this.initUserProfile();
  }

  private async initUserProfile() {
    try {
      const userProfile = await this.keycloak.loadUserProfile();
      this.firstName = userProfile.firstName;
      this.lastName = userProfile.lastName;
    } catch (error) {
      console.error('Failed to load user profile:', error);
    }
  }

  redirectToLoginPage(): Promise<void> {
    return this.keycloak.login();
  }

  get userName(): string {
    return this.keycloak.getUsername();
  }

  get userFirstName(): string | undefined {
    return this.firstName;
  }

  get userLastName(): string | undefined {
    return this.lastName;
  }

  async isLoggedIn(): Promise<boolean> {
    return this.keycloak.isLoggedIn();
  }

  hasAnyAuthority(authorities: string[]): boolean {
    return authorities.some(authority => this.keycloak.isUserInRole(authority));
  }

  hasAuthorities(): boolean {
    return this.hasAnyAuthority(Object.values(Authority));
  }

  logout(): void {
    this.keycloak.logout();
  }
}
