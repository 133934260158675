<div class="container">
  <header class="navbar navbar-expand-lg navbar-light py-4 mb-4">
    <a href="/" class="d-flex align-items-center text-dark text-decoration-none">
      <img src="assets/dbt-logo.png" alt="Logo" class="logo me-2">
    </a>

    <div class="collapse navbar-collapse ms-4" id="navbarNav" *ngIf="auth.hasAuthorities()">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" aria-current="page" routerLink="/dashboard">Tableau de board</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/tools">Outils</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/projects">Projets</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/services">Services</a>
        </li>
        <li class="nav-item" *ngIf="auth.hasAnyAuthority([Authority.ADMIN])">
          <button class="btn btn-primary" routerLinkActive="active" routerLink="/admin">Administration</button>
        </li>
      </ul>
    </div>

    <div
      class="me-2 p-2 h-100 text-white rounded ms-auto"
      [ngClass]="isVpn ? 'bg-success' : 'bg-danger'" style="font-size: 10px; font-weight: bolder"
    >
      {{ isVpn ? 'VPN CONNECTED' : 'VPN NOT CONNECTED' }}
    </div>

    <div class="dropdown ms-auto" *ngIf="auth.hasAuthorities()">
      <a class="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
         aria-expanded="false">
        {{ auth.userFirstName }} {{ auth.userLastName | uppercase }}
      </a>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li><a class="dropdown-item" routerLink="/profile">Mon Profile</a></li>
        <li><a class="dropdown-item" (click)="auth.logout()">Se déconnecter</a></li>
      </ul>
    </div>
  </header>

  <router-outlet></router-outlet>

</div>
