import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {AuthenticationService} from "../../auth/authentication.service";
import {Authority} from "../../auth/authority.constants";
import {VpnCheckService} from "../../services/vpn-check.service";

@Component({
  selector: 'app-base',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink, RouterLinkActive],
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {

  firstName: string | undefined;
  lastName: string | undefined;
  isVpn: boolean | undefined;

  protected readonly Authority = Authority;

  constructor(
    public auth: AuthenticationService,
    public vpn: VpnCheckService,
  ) {
  }

  ngOnInit() {
    this.vpn.isConnectedToVpn().subscribe(isConnected => {
      this.isVpn = isConnected
    });
  }

}
